@mixin font-size-default($size: 14) {
    $lineHeight: $size + 4;
    font-size: $size + px;
    line-height: $lineHeight + px;
}

@mixin mobile-block() {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

@mixin multi-line ($num) {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin single-line {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin button-base() {
    background-color: #666666;
    color: #fff;
    cursor: pointer;
    user-select: none;

    &:active {
        transform: translateY(1px);
    }
}
.card {
  // padding: 20px 30px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid var(--border-color);


  &>a {
    display: flex;
  }

  .repo-pic {
    margin-right: 12px;
    border-radius: 2px;
    width: 140px;
    height: 80px;
    object-fit: cover;
    object-position: center;
  }
  .repo-header-wrap {
    flex: 1;
    overflow: hidden;
  }
  .repo-header {
    flex: 1;
    display: flex;
    align-items: center;

    .repo-type {
      color: rgb(183, 30, 215);
      margin-right: 2px;
    }

    .repo-title {
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .repo-desc {
    color: var(--text-third-color);
    @include multi-line(1);
    margin: 4px 0;
  }

  .repo-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #999;

    .info-block {
      margin: 0 8px;
      font-size: 12px;
      margin: 0;

      // span {
        // margin-left: 4px;
      // }
    }
  }

  .actions {
    position: absolute;
    top: 4px;
    right: 24px;

    a {
      font-size: 12px;
      margin: 0 8px;
    }
  }

}