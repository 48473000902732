@mixin font-size-default($size: 14) {
    $lineHeight: $size + 4;
    font-size: $size + px;
    line-height: $lineHeight + px;
}

@mixin mobile-block() {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

@mixin multi-line ($num) {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin single-line {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin button-base() {
    background-color: #666666;
    color: #fff;
    cursor: pointer;
    user-select: none;

    &:active {
        transform: translateY(1px);
    }
}
$linkColor: #3a99d8;

.list {
  min-height: 100%;
  // margin: 24px auto 0;
  display: flex;

  &-content {
    flex: 1;
    overflow: hidden;
  }

  &-sidebar {
    width: 20rem;
    margin-left: 24px;
  }
}

.pagination {
  margin: 24px 0;
  text-align: center;
  display: flex;
  justify-content: center;

  :global {
    @media only screen and (max-width: 768px ){
      .ant-pagination-item,
      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {
        display: none !important;
      }

      .ant-pagination-prev {
        margin-right: 64px !important;
      }
    }
  }
}
