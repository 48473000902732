@mixin font-size-default($size: 14) {
    $lineHeight: $size + 4;
    font-size: $size + px;
    line-height: $lineHeight + px;
}

@mixin mobile-block() {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

@mixin multi-line ($num) {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin single-line {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin button-base() {
    background-color: #666666;
    color: #fff;
    cursor: pointer;
    user-select: none;

    &:active {
        transform: translateY(1px);
    }
}
.latest-article {
  background-color: var(--block-color);
  padding: 12px 12px;
  border-radius: var(--block-radius);
  // border: 1px solid var(--border-color);

  &-header {
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 8px;
  }

  &-list {
    padding: 4px 0;
    display: flex;
    flex-direction: column;
  }
}

.article {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include font-size-default(14);
  color: var(--text-second-color);
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
